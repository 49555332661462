import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="vertical-mapping-subcategories"
export default class extends Controller {
  static targets = ['subcategorySelectorsTemplate'];

  open(event) {
    this.modalOutlet.open(event);
  }

  systemFieldSelectChange(event) {
    const systemFieldSelect = event.target;

    this.resetSelectState(systemFieldSelect);

    if (systemFieldSelect.value === 'subcategory_code') {
      systemFieldSelect.parentNode.insertAdjacentHTML('afterend', this.subcategorySelectorsTemplateTarget.innerHTML);
    }
  }

  resetSelectState(select) {
    select
      .closest('tr.body-row')
      .querySelectorAll('.subcategory-field')
      .forEach((e) => e.remove());
  }
}
