import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rate-override-toggle"
export default class extends Controller {
  static targets = ["overrides", "originalRate"];

  toggle() {
    this.overridesTarget.classList.toggle("hidden");
    this.originalRateTarget.classList.toggle("hidden");
  }
}
